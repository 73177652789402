import { Account, AccountStats } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ResponsivePie } from '@nivo/pie'
import { CircleAlert, CircleCheck, CircleMinus, Crosshair, Earth, TriangleAlert } from 'lucide-react'
import { Trans, useTranslation } from 'react-i18next'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import ColorfulCard from '@/components/beeoimpact/colorful-card'
import ThreatenedSpeciesBadges from '@/components/beeoimpact/threatened-species-badge'
import CardsAnalytics from '@/components/cards-analytics'
import DashboardCard from '@/components/dashboard-card'
import IndicatorCard from '@/components/indicator-card'
import NoSitesMessage from '@/components/no-sites-message'
import { H4 } from '@/components/typography'

interface DashboardBiodiversityProps {
  stats?: AccountStats
  isLoadingStats: boolean
  isLoadingAccount: boolean
  account?: Account
}

const DashboardBiodiversity = ({ stats, isLoadingStats, isLoadingAccount, account }: DashboardBiodiversityProps) => {
  const { t } = useTranslation()

  // Destructure stats with defaults
  const {
    nb_sites = 0,
    nb_sites_with_in_sensitive_area = 0,
    nb_sites_with_adjacent_sensitive_area = 0,
    nb_sites_with_out_sensitive_area = 0,
    nb_species_in = { CR: 0, EN: 0, VU: 0, NT: 0, LC: 0 },
    nb_species_adjacent = { CR: 0, EN: 0, VU: 0, NT: 0, LC: 0 },
  } = stats || {}

  // Destructure species counts for sites within sensitive areas
  const {
    CR: speciesInCR = 0,
    EN: speciesInEN = 0,
    VU: speciesInVU = 0,
    NT: speciesInNT = 0,
    LC: speciesInLC = 0,
  } = nb_species_in

  // Destructure species counts for adjacent sensitive areas
  const {
    CR: speciesAdjacentCR = 0,
    EN: speciesAdjacentEN = 0,
    VU: speciesAdjacentVU = 0,
    NT: speciesAdjacentNT = 0,
    LC: speciesAdjacentLC = 0,
  } = nb_species_adjacent

  // Calculate totals for IUCN categories (only CR, EN, VU for overall threatened total)
  const totalIUCN = {
    CR: speciesInCR + speciesAdjacentCR,
    EN: speciesInEN + speciesAdjacentEN,
    VU: speciesInVU + speciesAdjacentVU,
    NT: speciesInNT + speciesAdjacentNT,
    LC: speciesInLC + speciesAdjacentLC,
  }
  const totalIUCNRedList = totalIUCN.CR + totalIUCN.EN + totalIUCN.VU

  // Calculate percentages for site distribution
  const notAdjacentPercentage = nb_sites ? (nb_sites_with_out_sensitive_area / nb_sites) * 100 : 0
  const adjacentPercentage = nb_sites ? (nb_sites_with_adjacent_sensitive_area / nb_sites) * 100 : 0
  const inPercentage = nb_sites ? (nb_sites_with_in_sensitive_area / nb_sites) * 100 : 0
  const percentageAdjacentIn = adjacentPercentage + inPercentage
  const totalAdjacentIn = nb_sites_with_adjacent_sensitive_area + nb_sites_with_in_sensitive_area

  // Data for the Pie Chart
  const dataPieChart = [
    {
      id: 'in',
      label: t(i18nKeys.beeoimpact.dashboard.metrics.pie.in),
      value: nb_sites_with_in_sensitive_area,
    },
    {
      id: 'not',
      label: t(i18nKeys.beeoimpact.dashboard.metrics.pie.notAdjacent),
      value: nb_sites_with_out_sensitive_area,
    },
    {
      id: 'adjacent',
      label: t(i18nKeys.beeoimpact.dashboard.metrics.pie.adjacent),
      value: nb_sites_with_adjacent_sensitive_area,
    },
  ]

  // Show message if there are no sites and stats are not loading
  if (nb_sites === 0 && !isLoadingStats) {
    return <NoSitesMessage />
  }

  return (
    <section>
      {/* Top Section: Definition and Summary Cards */}
      <div className="mb-12 flex flex-col">
        <div className="flex md:flex-col md:space-x-0 md:space-y-8 xl:flex-row xl:space-x-3 xl:space-y-3">
          <div className="flex w-full flex-col space-y-5 pt-3 md:flex-col lg:flex-row lg:space-x-3 lg:space-y-0 xl:flex-col xl:space-x-0 xl:space-y-5">
            {/* Sites Indicator Card */}
            <div className="flex-1">
              <IndicatorCard
                title={t(i18nKeys.beeoinitiative.dashboard.site, {
                  count: Number(account?.nb_sites),
                })}
                icon={<Earth className="size-8 text-biodivLimeFiveHundred" />}
                value={account?.nb_sites ?? '0'}
                linkText={t(i18nKeys.beeoinitiative.dashboard.seeAllSites)}
                to={getPath(Routes.BEEOIMPACT_SITES)}
                width="w-full"
                isLoading={isLoadingAccount}
                skeletonSize="h-[210px]"
              />
            </div>

            {/* Green Action Card */}
            <div className="flex-1">
              <Card className="flex min-h-[200px] w-full flex-col items-center justify-center space-y-4 bg-biodivTealSevenHundred text-white">
                <div className="flex flex-col items-center justify-center md:text-sm 2xl:text-sm">
                  <p>{t(i18nKeys.beeoimpact.dashboard.greenCard.title)}</p>
                  <p>{t(i18nKeys.beeoimpact.dashboard.greenCard.title2)}</p>
                </div>
                <div>
                  <Button
                    variant="outline"
                    className="border-white bg-biodivTealSevenHundred font-semibold md:text-xs 2xl:text-base"
                  >
                    <a href="mailto:info@beeodiversity.com?Subject=A biodiversity challenge">
                      {t(i18nKeys.beeoimpact.dashboard.greenCard.button)}
                    </a>
                  </Button>
                </div>
              </Card>
            </div>
          </div>

          {/* Metrics Dashboard Card */}
          <DashboardCard
            title={t(i18nKeys.beeoimpact.dashboard.metrics.repartition.title)}
            subtitle={t(i18nKeys.beeoimpact.dashboard.metrics.repartition.subtitle)}
            isLoading={false}
            width="w-[820px] md:w-full xl:w-[700px] 2xl:w-[950px]"
          >
            <div className="flex items-center justify-center pt-8 md:flex-col lg:flex-row lg:space-x-10">
              {/* Pie Chart (or loading state) */}
              {isLoadingStats ? (
                <Skeleton className="h-52 w-full md:w-36 lg:w-48 xl:w-52 2xl:w-56" />
              ) : (
                <div className="h-52 md:w-36 lg:w-48 xl:w-32 2xl:w-56">
                  <ResponsivePie
                    data={dataPieChart}
                    margin={{ top: 0, right: 4, bottom: 0, left: 4 }}
                    innerRadius={0.7}
                    padAngle={0.1}
                    cornerRadius={0}
                    activeOuterRadiusOffset={4}
                    enableArcLinkLabels={false}
                    enableArcLabels={false}
                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                    colors={['#DC2626', '#16A34A', '#EA580C']}
                    layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends']}
                  />
                </div>
              )}

              {/* Site Distribution Cards */}
              <div className="space-y-3 md:w-full">
                <ColorfulCard
                  bgColor="bg-green-50"
                  width="xl:w-[450px] 2xl:w-[640px]"
                  icon={<CircleCheck className="size-7 text-green-600" />}
                  text={
                    <Trans
                      i18nKey={t(i18nKeys.beeoimpact.dashboard.metrics.card.notAdjacent.title)}
                      values={{ notAdjacent: nb_sites_with_out_sensitive_area }}
                      components={{ bold: <span className="font-bold" /> }}
                    />
                  }
                  percentage={Math.floor(notAdjacentPercentage)}
                  height="h-[60px] 2xl:h-[80px]"
                  border="border border-green-600"
                  isLoadingStats={isLoadingStats}
                  skeletonClassName="h-[60px] xl:w-[450px] 2xl:h-[80px] 2xl:w-[640px]"
                />

                <div className="flex flex-row md:flex-col md:space-y-3 xl:flex-row xl:space-x-3 xl:space-y-0">
                  <div className="flex flex-col space-y-3">
                    <ColorfulCard
                      bgColor="bg-orange-50"
                      width="xl:w-[300px] 2xl:w-[450px]"
                      icon={<CircleAlert className="size-7 text-orange-600" />}
                      text={
                        <Trans
                          i18nKey={t(i18nKeys.beeoimpact.dashboard.metrics.card.adjacent.title)}
                          values={{ adjacent: nb_sites_with_adjacent_sensitive_area }}
                          components={{ bold: <span className="font-bold" /> }}
                        />
                      }
                      percentage={Math.floor(adjacentPercentage)}
                      height="h-[60px] 2xl:h-[80px]"
                      border="border border-orange-600"
                      isLoadingStats={isLoadingStats}
                      skeletonClassName="h-[60px] w-full xl:w-[300px] 2xl:w-[450px] 2xl:h-[80px]"
                    />

                    <ColorfulCard
                      bgColor="bg-red-50"
                      width="xl:w-[300px] 2xl:w-[450px]"
                      icon={<TriangleAlert className="size-7 text-red-600" />}
                      text={
                        <Trans
                          i18nKey={t(i18nKeys.beeoimpact.dashboard.metrics.card.in.title)}
                          values={{ adjacent: nb_sites_with_in_sensitive_area }}
                          components={{ bold: <span className="font-bold" /> }}
                        />
                      }
                      percentage={Math.floor(inPercentage)}
                      height="h-[60px] 2xl:h-[80px]"
                      border="border border-red-600"
                      isLoadingStats={isLoadingStats}
                      skeletonClassName="h-[60px] w-full xl:w-[300px] 2xl:w-[450px] 2xl:h-[80px]"
                    />
                  </div>

                  {isLoadingStats ? (
                    <Skeleton className="flex items-center space-y-2 border p-5 md:h-[60px] md:flex-row md:space-x-5 md:space-y-0 md:px-5 xl:h-[130px] xl:w-[140px] xl:flex-col xl:justify-center xl:space-x-0 xl:space-y-2 2xl:h-[170px] 2xl:w-[180px] 2xl:p-6" />
                  ) : (
                    <Card className="flex items-center space-y-2 border border-purple-500 bg-purple-50 p-5 md:h-[60px] md:flex-row md:space-x-5 md:space-y-0 md:px-5 xl:h-[130px] xl:w-[140px] xl:flex-col xl:justify-center xl:space-x-0 xl:space-y-2 2xl:h-[170px] 2xl:w-[180px] 2xl:p-6">
                      <div className="hidden md:block xl:hidden">
                        <CircleMinus className="size-7 text-purple-600" />
                      </div>
                      <p className="space-x-1 text-lg font-bold 2xl:text-xl">
                        <span>{Math.floor(percentageAdjacentIn)}</span>
                        <span>%</span>
                      </p>
                      <Separator orientation="horizontal" className="md:h-4 md:w-px xl:h-px xl:w-4" />
                      <p className="text-center text-sm">
                        <Trans
                          i18nKey={t(i18nKeys.beeoimpact.dashboard.metrics.card.in.title)}
                          values={{ adjacent: totalAdjacentIn }}
                          components={{ bold: <span className="font-bold" /> }}
                        />
                      </p>
                    </Card>
                  )}
                </div>
              </div>
            </div>
          </DashboardCard>
        </div>
      </div>

      <Separator />

      {/* Species Analytics and Data Table */}
      <div>
        <div className="mb-8 space-y-1">
          <H4>{t(i18nKeys.beeoimpact.dashboard.metrics.card.species.title)}</H4>
          <p className="text-sm text-muted-foreground">{t(i18nKeys.beeoimpact.dashboard.metrics.subtitle)}</p>
        </div>

        <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
          <CardsAnalytics
            icon={<Crosshair />}
            label={t(i18nKeys.beeoimpact.dashboard.indicators.threatened)}
            value={totalIUCNRedList}
            definition={t(i18nKeys.beeoimpact.dashboard.indicators.threatenedDefinition)}
            description={t(i18nKeys.beeoimpact.dashboard.metrics.card.species.description)}
            bgColor="bg-red-50 border border-red-500"
            isLoading={isLoadingStats}
          />

          <CardsAnalytics
            icon={<ThreatenedSpeciesBadges category="CR" />}
            label={t(i18nKeys.beeoimpact.dashboard.indicators.CR)}
            value={totalIUCN.CR}
            definition={t(i18nKeys.beeoimpact.dashboard.indicators.CRDefinition)}
            description={t(i18nKeys.beeoimpact.dashboard.metrics.card.species.CR.description)}
            isLoading={isLoadingStats}
          />

          <CardsAnalytics
            icon={<ThreatenedSpeciesBadges category="EN" />}
            label={t(i18nKeys.beeoimpact.dashboard.indicators.EN)}
            value={totalIUCN.EN}
            definition={t(i18nKeys.beeoimpact.dashboard.indicators.ENDefinition)}
            description={t(i18nKeys.beeoimpact.dashboard.metrics.card.species.EN.description)}
            isLoading={isLoadingStats}
          />
        </div>

        <div className="flex flex-col items-center justify-center lg:flex-row lg:space-x-8 lg:space-y-0">
          {isLoadingStats ? (
            <Skeleton className="h-[214px] w-full" />
          ) : (
            <Card className="my-8 flex w-full flex-col">
              <CardContent className="p-0">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead></TableHead>
                      <TableHead>{t(i18nKeys.beeoimpact.dashboard.groups.CR)}</TableHead>
                      <TableHead>{t(i18nKeys.beeoimpact.dashboard.groups.EN)}</TableHead>
                      <TableHead>{t(i18nKeys.beeoimpact.dashboard.groups.VU)}</TableHead>
                      <TableHead>{t(i18nKeys.beeoimpact.dashboard.groups.NT)}</TableHead>
                      <TableHead>{t(i18nKeys.beeoimpact.dashboard.groups.LC)}</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow>
                      <TableCell className="font-medium">
                        {t(i18nKeys.beeoimpact.dashboard.metrics.pie.adjacent)}
                      </TableCell>
                      <TableCell className="font-medium">{speciesAdjacentCR}</TableCell>
                      <TableCell>{speciesAdjacentEN}</TableCell>
                      <TableCell>{speciesAdjacentVU}</TableCell>
                      <TableCell>{speciesAdjacentNT}</TableCell>
                      <TableCell>{speciesAdjacentLC}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">{t(i18nKeys.beeoimpact.dashboard.metrics.pie.in)}</TableCell>
                      <TableCell className="font-medium">{speciesInCR}</TableCell>
                      <TableCell>{speciesInEN}</TableCell>
                      <TableCell>{speciesInVU}</TableCell>
                      <TableCell>{speciesInNT}</TableCell>
                      <TableCell>{speciesInLC}</TableCell>
                    </TableRow>
                    <TableRow className="text-sm font-bold">
                      <TableCell>Total</TableCell>
                      <TableCell>{totalIUCN.CR}</TableCell>
                      <TableCell>{totalIUCN.EN}</TableCell>
                      <TableCell>{totalIUCN.VU}</TableCell>
                      <TableCell>{totalIUCN.NT}</TableCell>
                      <TableCell>{totalIUCN.LC}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          )}
        </div>
      </div>
    </section>
  )
}

export default DashboardBiodiversity
