import { useMemo } from 'react'
import { SensitiveAreaOut, SiteGeometry } from '@/client/backend/models'
import * as turf from '@turf/turf'
import { LatLng, PathOptions } from 'leaflet'
import { useTranslation } from 'react-i18next'
import {
  Circle,
  LayerGroup,
  LayersControl,
  MapContainer,
  Polygon,
  ScaleControl,
  TileLayer,
  Tooltip,
} from 'react-leaflet'

import { convertGeoJsonToGeometry, DEFAULT_RADIUS, GeoJsonArea, getBoundsFromCenterAndRadius } from '@/lib/geo'
import MapBadge from '@/components/beeoimpact/impacts/map-badge'

interface SiteProtectedZonesMapProps {
  protectedAreas?: SensitiveAreaOut[]
  designatedAreas?: SensitiveAreaOut[]
  center: LatLng
  area?: SiteGeometry
  radius?: number
}

const SiteProtectedZonesMap = ({ protectedAreas, center, designatedAreas, radius }: SiteProtectedZonesMapProps) => {
  const { t } = useTranslation()
  const mapRadius = radius ?? DEFAULT_RADIUS
  const mapBounds = useMemo(() => {
    return getBoundsFromCenterAndRadius(center, mapRadius)
  }, [center, mapRadius])

  function renderAreas() {
    const createPolygonOptions = (): PathOptions => ({
      color: 'white',
      fillColor: 'orange',
      fillOpacity: 0.5,
      weight: 1,
      opacity: 0.5,
    })

    const areas =
      protectedAreas === null
        ? []
        : protectedAreas?.map((protectedArea, idx) => {
            const options = createPolygonOptions()
            const newGeom = convertGeoJsonToGeometry(protectedArea.intersection as GeoJsonArea)

            if (!newGeom) {
              return null
            }

            return (
              <Polygon key={idx} pathOptions={options} positions={newGeom} pane="markerPane" className="z-0">
                <Tooltip sticky>
                  <ul>
                    <li>{protectedArea.name}</li>
                    <li>{protectedArea.type && t(`beeoimpact.protected-zones-map.types.${protectedArea.type}`)}</li>
                    <li>{(turf.area(protectedArea.intersection) / 10000).toFixed(2)} ha</li>
                  </ul>
                </Tooltip>
              </Polygon>
            )
          })

    return areas
  }

  function renderCddas() {
    const createPolygonOptions = (): PathOptions => ({
      color: 'white',
      fillColor: 'darkblue',
      fillOpacity: 0.5,
      weight: 1,
      opacity: 0.5,
    })
    const nationallyDesignatedAreas =
      designatedAreas === null
        ? []
        : designatedAreas?.map((designatedArea, idx) => {
            const options = createPolygonOptions()
            const newGeom = convertGeoJsonToGeometry(designatedArea.intersection as GeoJsonArea)

            if (!newGeom) {
              return null
            }

            return (
              <Polygon key={idx} pathOptions={options} positions={newGeom} pane="markerPane" className="z-0">
                <Tooltip sticky>
                  <ul>
                    <li>{designatedArea.name}</li>
                  </ul>
                </Tooltip>
              </Polygon>
            )
          })

    return nationallyDesignatedAreas
  }

  return (
    <MapContainer
      className="z-0 min-h-[30rem] w-[25rem] rounded-l-md"
      bounds={mapBounds}
      scrollWheelZoom={false}
      attributionControl={false}
      zoomSnap={0.1}
    >
      <Circle center={center} color={'cadetblue'} fillColor="cadetblue" radius={mapRadius} />
      <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <MapBadge content={`${((radius ?? 0) / 1000).toFixed(1)} km radius`} />
      <LayersControl position="bottomright">
        <LayersControl.Overlay checked name="CDDA">
          <LayerGroup>{renderCddas()}</LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Natura 2000">
          <LayerGroup>{renderAreas()}</LayerGroup>
        </LayersControl.Overlay>
      </LayersControl>
      <ScaleControl position="bottomleft" />
    </MapContainer>
  )
}

export default SiteProtectedZonesMap
