import { useEffect } from 'react'
import L, { Control } from 'leaflet'
import { useMap } from 'react-leaflet'

interface MapBadgeProps {
  content: string | number
}

const MapBadge = ({ content }: MapBadgeProps) => {
  const map = useMap()

  useEffect(() => {
    const CustomControl = Control.extend({
      onAdd: function () {
        const div = L.DomUtil.create('div', 'custom-icon')
        div.className =
          'bg-white text-black text-center px-2 py-1 rounded-xl shadow-md flex items-center space-x-2 text-xs'
        div.innerHTML = `<span>${content}</span>`
        return div
      },
    })

    const customControl = new CustomControl({ position: 'topright' })
    customControl.addTo(map)

    // Cleanup on unmount
    return () => {
      customControl.remove()
    }
  }, [map, content])

  return null // This component doesn't render anything itself
}

export default MapBadge
