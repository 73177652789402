import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts'

import { ChartTooltip } from '@/components/ui/chart'

interface sortedPesticidesProps {
  name: string
  occurrence: number
}
interface sortedCropsProps {
  name: string
  hectare: number
}
interface MostActiveSitesProps {
  data: sortedPesticidesProps[] | sortedCropsProps[]
  valueKey: string
  barColor: string
  nameKey: string
  tooltipColor: string
  responsiveContainerWidth: string
}

const PesticidesChart = ({
  data,
  nameKey,
  valueKey,
  barColor = 'hsl(220, 80%, 60%)',
  tooltipColor,
  responsiveContainerWidth,
}: MostActiveSitesProps) => {
  const sortedData = (data ?? []).sort((a, b) => b[valueKey] - a[valueKey]).slice(0, 10)

  const CustomTooltip = ({ active, payload }: { active?; payload? }) => {
    if (active && payload && payload.length) {
      const { [nameKey]: name, [valueKey]: value } = payload[0].payload

      return (
        <div className="rounded-md bg-muted p-2 shadow-md">
          <div className="flex items-center justify-evenly">
            <div className="mr-2 size-2 rounded-full" style={{ backgroundColor: tooltipColor }} />
            <span>{name.toUpperCase()}</span>
            <span className="ml-5 text-muted-foreground">{value.toFixed()}</span>
          </div>
        </div>
      )
    }
    return null
  }
  const maxValue = Math.max(...sortedData.map((item) => item[valueKey]))

  const step = maxValue < 50 ? 2 : maxValue > 500 ? 100 : 10
  return (
    <div className="space-y-4">
      <div className="xl:pt-6">
        <ResponsiveContainer width={responsiveContainerWidth} maxHeight={630} minHeight={630}>
          <BarChart barSize={12} data={sortedData} layout="vertical">
            <CartesianGrid
              strokeDasharray="3 3"
              vertical={true}
              horizontal={false}
              horizontalPoints={sortedData.map((_, index) => index * 40)}
            />
            <XAxis
              type="number"
              dataKey={valueKey}
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12, fill: '#000', dy: 4 }}
              ticks={Array.from({ length: Math.ceil(maxValue / step) + 1 }, (_, i) => i * step)}
              label={{
                value: valueKey,
                position: 'insideLeft',
                angle: 0,
                offset: -90,
                style: {
                  textAnchor: 'start',
                  fontSize: 14,
                  fontWeight: 'bold',
                  fill: '#000',
                  textTransform: 'capitalize',
                },
              }}
            />
            <YAxis
              type="category"
              dataKey={nameKey}
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 14, fill: '#000' }}
              width={150}
              padding={{ top: 1, bottom: 1 }}
              tickFormatter={(value) => {
                const maxLength = 15
                return value.length > maxLength ? `${value.substring(0, maxLength)}...` : value
              }}
            />
            <Bar dataKey={valueKey} fill={barColor} radius={[0, 20, 20, 0]} />
            <ChartTooltip cursor={false} content={<CustomTooltip />} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default PesticidesChart
