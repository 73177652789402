import { i18nKeys } from '@/locales/keys'
import { BarDatum, ResponsiveBar } from '@nivo/bar'
import { CartesianMarkerProps, DatumValue } from '@nivo/core'
import { useTranslation } from 'react-i18next'

import { BarChartType } from '@/types/barchart-type'
import { BarChartValueWithColor } from '@/types/graph'
import { cn } from '@/lib/utils'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'

type CardBarChartProps = {
  size?: string
  title?: string
  subtitle?: string
  data: BarChartValueWithColor[]
  colors: string[]
  showLegend?: boolean
  lmrValue?: number
  loqValue?: number
  legendY: string
  authorized?: boolean
  type?: BarChartType
  rounded?: number
}

const CardBarChart = ({
  size,
  title,
  subtitle,
  data,
  colors,
  showLegend = false,
  lmrValue,
  loqValue,
  legendY,
  authorized = true,
  rounded = 3,
  type = BarChartType.PESTICIDES,
}: CardBarChartProps) => {
  const { t } = useTranslation()

  const legends = [
    {
      label: t(i18nKeys.beeomonitoring.common.graph.legend.local_value),
      color: data[0] ? data[0].color : colors[0],
    },
    { label: t(i18nKeys.beeomonitoring.common.graph.legend.average_value), color: colors[1] },
  ]

  const markerName =
    type === BarChartType.PESTICIDES ? (lmrValue?.toFixed(3) === loqValue?.toFixed(3) ? 'LMR - LQ' : 'LMR') : 'TMA'
  const markers: CartesianMarkerProps<DatumValue>[] = [
    ...(lmrValue !== undefined
      ? [
          {
            axis: 'y' as const,
            value: lmrValue,
            lineStyle: {
              stroke: 'rgba(255, 0, 0, .35)',
              strokeWidth: 2,
            },
            textStyle: {
              fill: '#000000',
              fontSize: 10,
            },
            legend: markerName,
            legendOrientation: 'horizontal' as const,
            legendPosition: 'top-right' as const,
          },
        ]
      : []),
    ...(loqValue !== undefined
      ? [
          {
            axis: 'y' as const,
            value: loqValue,
            lineStyle: {
              stroke: 'rgba(0, 0, 0, .35)',
              strokeWidth: 2,
            },
            textStyle: {
              fill: '#000000',
              fontSize: 10,
            },

            legend: lmrValue?.toFixed(3) === loqValue?.toFixed(3) ? 'LMR - LQ' : 'LQ',
            legendOrientation: 'horizontal' as const,
          },
        ]
      : []),
  ]

  const maxValue =
    Math.max(
      loqValue ?? 0,
      lmrValue ?? 0,
      data[0] ? data[0].local_value : 0,
      data[0] ? (data[0].average_value ?? 0) : 0
    ) * 1.25

  return (
    <Card className={size}>
      <CardHeader>
        <CardTitle className={cn('flex items-center ', authorized ? 'text-foreground' : 'text-red-600')}>
          {title}
        </CardTitle>
        <CardDescription>{subtitle}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="mx-auto flex h-[300px] w-full items-center justify-center">
          <ResponsiveBar
            data={data as unknown as BarDatum[]}
            /*colors={({ id, data, index }) => {
              return id === 'local_value' ? data.color : colors[index + 1]
            }}*/
            colors={colors}
            keys={['local_value', 'average_value']}
            indexBy={'value'}
            labelTextColor="#FFFFFF"
            padding={0.4}
            innerPadding={10}
            margin={{ top: 5, right: 20, bottom: 10, left: 60 }}
            layout="vertical"
            enableLabel={true}
            valueFormat={(value) => `${value.toFixed(rounded)} `}
            enableGridY={true}
            enableGridX={false}
            groupMode="grouped"
            axisLeft={{
              tickPadding: 10,
              tickSize: 0,
              tickRotation: 0,
              legend: legendY,
              legendOffset: -55,
              legendPosition: 'middle',
            }}
            maxValue={maxValue}
            axisBottom={null}
            tooltip={({ id, value, color }) => (
              <div className="rounded-md bg-muted p-2 shadow-md">
                <div className="flex items-center justify-evenly">
                  <div className={cn('mr-2 size-2 rounded-full')} style={{ backgroundColor: color }} />
                  <span>{t(i18nKeys.beeomonitoring.common.graph.legend[id]).toLowerCase()}</span>
                  <span className="ml-5 text-muted-foreground">{value.toFixed(rounded)}</span>
                </div>
              </div>
            )}
            markers={markers}
          />
        </div>

        {showLegend && (
          <div className="flex min-h-[50px] flex-wrap justify-center">
            {legends.map((legend, index) => (
              <div key={index} className="mr-4 flex items-center text-xs text-muted-foreground">
                <div
                  className={cn(' mr-2 size-3 rounded-full')}
                  style={{ backgroundColor: colors[index % colors.length] }}
                />
                <span>{legend.label}</span>
              </div>
            ))}
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default CardBarChart
