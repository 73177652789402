import { Link } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { cn } from '@/lib/utils'
import { useAuth } from '@/hooks/useAuth'
import { Button } from '@/components/ui/button'
import LanguageSelector from '@/components/language-selector'
import UserNav from '@/components/user-nav'

const Header = () => {
  const { user, isImpersonate } = useAuth()
  const bgHeader = isImpersonate ? 'bg-red-200' : null

  return (
    <header
      className={cn(
        'supports-backdrop-blur:bg-background/60 sticky top-0 z-50 flex h-[70px] items-center justify-end border-b bg-background/95 px-8 backdrop-blur',
        bgHeader
      )}
    >
      <LanguageSelector />

      {/* Add a separator */}
      <div className="w-5" />

      {user ? (
        <UserNav />
      ) : (
        <Button asChild>
          <Link to={getPath(Routes.AUTH_LOGIN)} rel="noreferrer">
            Login
          </Link>
        </Button>
      )}
    </header>
  )
}

export default Header
