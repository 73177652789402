import { CLCPlot, Habitat, Site, SpeciesSample } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { LatLng } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { Circle, LayerGroup, LayersControl, MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet'
import { NavLink } from 'react-router-dom'

import { ZoneWithPercentage } from '@/types/zone'
import { ExternalLinks } from '@/lib/constants/external-links'
import { getGbifMarkers } from '@/lib/gbif'
import { DEFAULT_RADIUS } from '@/lib/geo'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import MapBadge from '@/components/beeoimpact/impacts/map-badge'
import SiteLocationMap from '@/components/beeoimpact/impacts/site-location-map'
import { Icons } from '@/components/icons'
import { getCustomMapIcon } from '@/components/icons-components/map-icons'
import RenderTooltip from '@/components/render-tooltip/render-tooltip'

type GeneralAnalysisSummaryProps = {
  clc?: CLCPlot[]
  center?: LatLng
  zoneWithPercentage: ZoneWithPercentage[]
  site?: Site
  gbif?: SpeciesSample[]
  threatenedSpeciesNumber?: number
  isLoadingGbif: boolean
  isLoadingClc: boolean
  habitats?: Habitat[]
  isLoadingHabitats: boolean
}

const GeneralAnalysisSummary = ({
  site,
  clc,
  center,
  zoneWithPercentage,
  gbif,
  threatenedSpeciesNumber,
  isLoadingGbif,
  isLoadingClc,
  habitats,
  isLoadingHabitats,
}: GeneralAnalysisSummaryProps) => {
  const { t } = useTranslation()
  const isLoading = isLoadingClc || isLoadingGbif || isLoadingHabitats
  const hasData =
    (zoneWithPercentage && zoneWithPercentage.length > 0) || (threatenedSpeciesNumber && threatenedSpeciesNumber > 0)

  const markers = gbif ? getGbifMarkers(gbif) : []

  const firstHabitat = habitats?.[0]?.name ?? ''

  return (
    <div className="my-4 flex">
      {isLoading ? (
        <div className="flex w-full">
          <Skeleton className="h-[36rem] w-full rounded-md" />
        </div>
      ) : hasData ? (
        <>
          <div>
            <MapContainer
              className="z-0 min-h-[36rem] w-[25rem] rounded-l-md"
              zoomSnap={0.1}
              center={center}
              zoom={13}
              scrollWheelZoom={false}
              attributionControl={true}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution={`&copy; <a href="${ExternalLinks.Clc}">${t(
                  i18nKeys.beeoimpact.site.attribution.clc
                )}</a> &copy; <a href="${ExternalLinks.Gbif}">${t(i18nKeys.beeoimpact.site.attribution.gbif)}</a>`}
              />

              <MapBadge content={`${(DEFAULT_RADIUS / 1000).toFixed(1)} km radius`} />

              {center && <Circle center={center} color="cadetblue" fillColor="cadetblue" radius={DEFAULT_RADIUS} />}

              <LayersControl position="bottomright" collapsed={false}>
                <LayersControl.Overlay checked name={t(i18nKeys.beeoimpact.site.layer.clc)}>
                  <LayerGroup>{clc?.map((c) => <SiteLocationMap clc={c} key={c.id} />)}</LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay checked name={t(i18nKeys.beeoimpact.site.layer.gbif)}>
                  <LayerGroup>
                    {markers.map((marker) => (
                      <Marker
                        position={marker.marker}
                        interactive={true}
                        icon={getCustomMapIcon({ category: marker.category })}
                        key={marker.id}
                        pane="markerPane"
                        zIndexOffset={1000}
                      >
                        <Tooltip>{marker.name}</Tooltip>
                      </Marker>
                    ))}
                  </LayerGroup>
                </LayersControl.Overlay>
              </LayersControl>
            </MapContainer>
          </div>
          <Card className="min-h-[36rem] w-full rounded-l-none">
            <CardHeader className="flex items-center justify-end">
              <CardTitle className="flex items-center space-x-2">
                <div>{t(i18nKeys.beeoimpact.site.dashboard.sitePotential)}</div>
                <div>
                  <RenderTooltip
                    size={24}
                    explanationText={t(i18nKeys.beeoimpact.site.biodiversityAround.generalAnalysisDefinition)}
                  />
                </div>
              </CardTitle>

              <NavLink
                className="ml-auto flex items-center"
                to={getPath(Routes.BEEOIMPACT_SITE_GENERAL_ANALYSIS, {
                  params: { siteId: site?.id as number },
                })}
              >
                <Button variant="link" size="sm" className="flex items-center">
                  {t(i18nKeys.beeoimpact.site.buttonDetails)}
                  <Icons.MoveRight className="ml-2 size-4" />
                </Button>
              </NavLink>
            </CardHeader>
            <CardContent className="flex flex-col overflow-auto">
              <div className="flex flex-col space-y-4 overflow-auto">
                {zoneWithPercentage.map((zone) => (
                  <div className="flex space-x-4" key={zone.key}>
                    {zone.icon}
                    <div>
                      <p>{zone.percentage.toFixed(2)} %</p>
                      <p className="text-muted-foreground">{zone.zone}</p>
                    </div>
                  </div>
                ))}
                <div className="flex space-x-4">
                  <div>
                    <Icons.Squirrel />
                  </div>
                  <div>
                    <p>{gbif?.length}</p>
                    <p className="text-gray-500">{t(i18nKeys.beeoimpact.site.biodiversityAround.invasiveSpecies)}</p>
                  </div>
                </div>
                <div className="flex space-x-4">
                  <div>
                    <Icons.Bird />
                  </div>
                  <div>
                    <p>{threatenedSpeciesNumber}</p>
                    <p className="text-gray-500">{t(i18nKeys.beeoimpact.site.biodiversityAround.threatenedSpecies)}</p>
                  </div>
                </div>
                {firstHabitat ? (
                  <div className="flex space-x-4">
                    <div>
                      <Icons.House />
                    </div>
                    <div>
                      <p>{firstHabitat}</p>
                      <p className="text-gray-500">
                        {t(i18nKeys.beeoimpact.site.biodiversityAround.mostProbableHabitat)}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </CardContent>
          </Card>
        </>
      ) : (
        <div className="container mt-4 flex h-80 items-center justify-center rounded-md border border-dashed">
          <div className="mx-auto flex max-w-[420px] flex-col items-center text-center">
            <Icons.Leaf size={42} />
            <h3 className="mt-4 text-lg font-semibold">
              {t(i18nKeys.beeoimpact.site.biodiversityAround.clcGbifNoData2)}
            </h3>
            <p className="mb-4 mt-2 text-sm text-muted-foreground">
              {t(i18nKeys.beeoimpact.site.biodiversityAround.clcGbifNoData)}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default GeneralAnalysisSummary
