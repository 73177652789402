import { CLCPlot } from '@/client/backend/models'
import { Polygon, Tooltip } from 'react-leaflet'

import { MapLayer } from '@/types/map-layer'
import { convertGeoJsonToGeometry, GeoJsonArea } from '@/lib/geo'

interface SiteLocationMapProps {
  clc: CLCPlot
  layer?: MapLayer
  highlightClc?: boolean
}

const SiteLocationMap = ({ clc, layer, highlightClc = false }: SiteLocationMapProps) => {
  const newGeom = convertGeoJsonToGeometry(clc.intersection as GeoJsonArea)
  if (!newGeom) {
    return null
  }

  let fillColor = highlightClc ? 'gray' : clc.type.color
  let intensity = 0.5
  if (layer === 'fa' && clc.type.fa_factor) {
    fillColor = 'green'
    intensity = clc.type.fa_factor
  } else if (layer === 'ns' && clc.type.ns_factor) {
    fillColor = 'red'
    intensity = clc.type.ns_factor
  }

  const options = {
    color: 'white',
    fillColor: fillColor ? fillColor : 'white',
    fillOpacity: intensity ? intensity : 0.5,
    weight: 1,
    opacity: 2,
  }

  return (
    <Polygon pathOptions={options} positions={newGeom} className="outline-none" pane="markerPane">
      <Tooltip sticky>
        {highlightClc ? (
          <>
            <p>{clc.type.name_level3}</p>
            {layer === 'fa' && <p>FA : {clc.type.fa_factor ?? 'FA score not computed'}</p>}
            {layer === 'ns' && <p>NS : {clc.type.ns_factor ?? 'NS score not computed'}</p>}
          </>
        ) : (
          <p>{clc.type.name_level3}</p>
        )}
      </Tooltip>
    </Polygon>
  )
}

export default SiteLocationMap
