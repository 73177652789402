import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * I18nNumberFormatter Component
 * Formats a number according to the user's locale and provided options.
 */
const I18nNumberFormatter = ({
  value,
  options = {},
}: {
  value: number
  options?: Intl.NumberFormatOptions
}) => {
  const { i18n } = useTranslation()
  const formattedValue = useMemo(() => {
    return new Intl.NumberFormat(i18n.language, options).format(value)
  }, [value, options, i18n.language])

  return <>{formattedValue}</>
}

export default I18nNumberFormatter
