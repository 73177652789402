import { useEffect, useRef, useState } from 'react'
import { CLCPlot, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { GbifMarker } from '@/pages/beeoimpact/general-analysis/general-analysis'
import { LatLng } from 'leaflet'
import { useTranslation } from 'react-i18next'
import {
  Circle,
  LayerGroup,
  LayersControl,
  MapContainer,
  Marker,
  ScaleControl,
  TileLayer,
  Tooltip,
  useMap,
} from 'react-leaflet'

import { MapLayer } from '@/types/map-layer'
import { ExternalLinks } from '@/lib/constants/external-links'
import { DEFAULT_RADIUS } from '@/lib/geo'
import { Card } from '@/components/ui/card'
import MapBadge from '@/components/beeoimpact/impacts/map-badge'
import SiteLocationMap from '@/components/beeoimpact/impacts/site-location-map'
import { getCustomMapIcon } from '@/components/icons-components/map-icons'

interface MapProps {
  center: LatLng
  gbifMarkers: GbifMarker[]
  clc: CLCPlot[]
  site?: Site
}

const ClcGbifMap = ({ center, gbifMarkers, clc, site }: MapProps) => {
  const [activeLayers, setActiveLayers] = useState({
    FA: false,
    NS: false,
  })
  const mapRef = useRef<L.Map>(null)
  const { t } = useTranslation()

  const LayerChangeHandler = () => {
    const map = useMap()

    useEffect(() => {
      map.on('overlayadd', (e) => {
        if (e.name === 'Flower availability (FA)') {
          setActiveLayers((prev) => ({
            ...prev,
            FA: true,
          }))
        } else if (e.name === 'Nesting suitability (NS)') {
          setActiveLayers((prev) => ({
            ...prev,
            NS: true,
          }))
        }
      })

      map.on('overlayremove', (e) => {
        if (e.name === 'Flower availability (FA)') {
          setActiveLayers((prev) => ({
            ...prev,
            FA: false,
          }))
        } else if (e.name === 'Nesting suitability (NS)') {
          setActiveLayers((prev) => ({
            ...prev,
            NS: false,
          }))
        }
      })
    }, [map])

    return null
  }

  const getBadgeContent = (): string => {
    if (!site) return ''
    if (activeLayers.FA && activeLayers.NS) {
      return `FA: ${site.properties?.fa_index?.toFixed(2) ?? ''} NS: ${site.properties?.ns_index?.toFixed(2) ?? ''}`
    }
    if (activeLayers.FA) {
      return `FA: ${site.properties?.fa_index?.toFixed(2) ?? ''}`
    }
    if (activeLayers.NS) {
      return `NS: ${site.properties?.ns_index?.toFixed(2) ?? ''}`
    }
    return ''
  }

  return (
    <div className="flex w-full gap-4">
      <div className="w-3/4">
        <MapContainer
          className="z-0 h-[30rem] rounded-md"
          center={center}
          zoom={13.9}
          zoomSnap={0.1}
          scrollWheelZoom={false}
          attributionControl={true}
          ref={mapRef}
        >
          <Circle center={center} color={'cadetblue'} fillColor="cadetblue" radius={DEFAULT_RADIUS} />
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution={`&copy; <a href="${ExternalLinks.Clc}">${t(
              i18nKeys.beeoimpact.site.attribution.clc
            )}</a> &copy; <a href="${ExternalLinks.Gbif}"> ${t(i18nKeys.beeoimpact.site.attribution.gbif)}</a>
           `}
          />
          <LayerChangeHandler />

          <div className="flex items-center justify-center">
            <MapBadge content={`${(DEFAULT_RADIUS / 1000).toFixed(1)} km radius`} />
            {getBadgeContent() && <MapBadge content={getBadgeContent()} />}
          </div>

          <LayersControl position="bottomright" collapsed={false}>
            <LayersControl.Overlay name="Flower availability (FA)">
              <LayerGroup>
                {clc?.map((c) => <SiteLocationMap clc={c} key={c.id} layer={MapLayer.FA} highlightClc={true} />)}
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Nesting suitability (NS)">
              <LayerGroup>
                {clc?.map((c) => <SiteLocationMap clc={c} key={c.id} layer={MapLayer.NS} highlightClc={true} />)}
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name={t(i18nKeys.beeoimpact.site.layer.clc)}>
              <LayerGroup>
                {clc?.map((c) => {
                  return <SiteLocationMap clc={c} key={c.id} />
                })}
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name={t(i18nKeys.beeoimpact.site.layer.gbif)}>
              <LayerGroup>
                {gbifMarkers.map((marker) => (
                  <Marker
                    position={marker.marker}
                    interactive={true}
                    icon={getCustomMapIcon({ category: marker.category })}
                    key={marker.id}
                    pane="markerPane"
                    zIndexOffset={1000}
                  >
                    <Tooltip>{marker.name}</Tooltip>
                  </Marker>
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
          </LayersControl>
          <ScaleControl position="bottomleft" />
        </MapContainer>
      </div>

      <div className="flex h-[30rem] w-1/4 flex-col gap-4">
        <Card className="flex h-60 w-full flex-col">
          <div className="flex h-full flex-col items-center justify-center text-center">
            <p className="pb-1 text-center text-xl font-semibold md:text-center">Flower availability</p>
            <p className="pb-2 text-center md:text-center">score</p>
            <p className="text-center text-xl font-semibold text-biodivTealSevenHundred md:text-center">
              {site?.properties?.fa_index?.toFixed(2) ?? ''}
            </p>
          </div>
        </Card>

        <Card className="flex h-60 w-full flex-col">
          <div className="flex h-full flex-col items-center justify-center text-center">
            <p className="pb-1 text-center text-xl font-semibold md:text-center">Nesting suitability</p>
            <p className="pb-2 text-center md:text-center">score</p>
            <p className="text-center text-xl font-semibold text-biodivTealSevenHundred md:text-center">
              {site?.properties?.ns_index?.toFixed(2) ?? ''}
            </p>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default ClcGbifMap
