import { NaceCode } from '@/client/backend/models'
import { DefaultHeatMapDatum, HeatMapSerie, ResponsiveHeatMap } from '@nivo/heatmap'

import { pressureValue, pressureValueToColor, pressureValueToLabel } from '@/lib/constants/pressure'

interface HabitatHeatmapProps {
  naceCodes: NaceCode[]
}

const NaceCodeHeatmap = ({ naceCodes }: HabitatHeatmapProps) => {
  const filteredHabitats = naceCodes.filter((naceCode) => (naceCode.pressures ?? []).length > 0)
  const isSingleRow = filteredHabitats.length === 1

  const baseHeight = 100 // base height per row
  const minHeight = 200 // minimum height
  const height = Math.max(filteredHabitats.length * baseHeight, minHeight)

  const da = filteredHabitats.map((naceCode) => {
    return {
      id: naceCode.name || '',
      data: (naceCode.pressures ?? []).map((pressure) => {
        return {
          x: pressure.name || '',
          y: pressureValue[pressure.value],
          label: pressure.value,
        }
      }),
    }
  })

  return (
    <div
      className="relative w-full"
      style={{
        height: `${height}px`,
        minHeight: `${minHeight}px`,
      }}
    >
      <ResponsiveHeatMap
        data={da as unknown as HeatMapSerie<DefaultHeatMapDatum, Record<string, never>>[]}
        margin={{ top: 0, right: 10, bottom: 120, left: 100 }}
        axisTop={null}
        isInteractive={!isSingleRow}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: -90,

          renderTick: ({ value, x, y }) => (
            <g transform={`translate(${x},${y})`}>
              <foreignObject x={-60} y={0} width={120} height={120}>
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    marginLeft: '40px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    fontSize: '10px',
                    transform: 'rotate(-90deg)',
                    transformOrigin: 'center center',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    lineHeight: '1.2',
                    textAlign: 'left',
                    padding: '2px',
                  }}
                >
                  {value}
                </div>
              </foreignObject>
            </g>
          ),
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 20,
          tickRotation: 0,
          ticksPosition: 'before',
          truncateTickAt: 51,
          renderTick: ({ value, x, y }) => (
            <g transform={`translate(${x},${y})`}>
              <foreignObject x={-100} y={-10} width={100} height={50}>
                <div
                  style={{
                    width: '150px',

                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingRight: '60px',
                    fontSize: '10px',
                    color: '#000',
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'center',
                    lineHeight: '1.2',
                    fontFamily: 'Arial',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    textDecoration: 'none',
                    letterSpacing: 'normal',
                    textTransform: 'none',
                    backgroundColor: 'transparent',
                    border: 'none',
                    outline: 'none',
                  }}
                >
                  {value}
                </div>
              </foreignObject>
            </g>
          ),
        }}
        //renderTick: CustomTick,

        colors={(cell) => pressureValueToColor[cell.value as number]}
        emptyColor="#555555"
        // format the cell to use the label and not the value
        valueFormat={(value) => pressureValueToLabel[value]}
      />
    </div>
  )
}

export default NaceCodeHeatmap
