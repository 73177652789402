import { useEffect, useMemo, useRef, useState } from 'react'
import {
  getSitesIdNameCenterListQueryKey,
  getSitesListQueryKey,
  sitesIdNameCenterList,
  sitesList,
} from '@/client/backend/api/sites/sites'
import { SitesListParams } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useLocation, useSearchParams } from 'react-router-dom'

import { FeatureType } from '@/types/feature-type'
import { ScoringType } from '@/types/scoring-type'
import { SortType } from '@/types/sort-type'
import { AVAILABLE_FILTERS } from '@/lib/available-filters'
import { MODULE } from '@/lib/module'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { cn } from '@/lib/utils'
import { useAuth } from '@/hooks/useAuth'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import BreadcrumbComponent from '@/components/breadcrumb-component'
import { Icons } from '@/components/icons'
import Loader from '@/components/loader'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'
import SitesTableBiodiversity from '@/components/sites-table-biodiversity'
import SitesTablePesticides from '@/components/sites-table-pesticides'
import SitesCards from '@/components/sites/sites-cards'
import { SitesFilters } from '@/components/sites/sites-filters'
import { SitesMapContainer } from '@/components/sites/sites-map-container'

interface SitesPageViewProps {
  module: MODULE
  availableFilters?: AVAILABLE_FILTERS[]
}

type ViewType = 'cards' | 'list'

const PAGE_SIZE = 12

const getModuleRoute = (module: MODULE): Routes => {
  const routeMap = {
    [MODULE.AUDIT]: Routes.BEEOAUDIT_SITES,
    [MODULE.MONITORING]: Routes.BEEOMONITORING_SITES,
    [MODULE.IMPACT]: Routes.BEEOIMPACT_SITES,
  }
  return routeMap[module] || Routes.BEEOIMPACT_SITES
}

const useSitesNavigation = (module: MODULE) => {
  const navigate = useNavigate()

  const navigateWithParams = (queryParams: SitesListParams) => {
    const route = getModuleRoute(module)
    navigate(getPath(route, { queryParams }), { replace: true })
  }

  const getModulePath = () => {
    const route = getModuleRoute(module)
    return getPath(route)
  }

  return { navigateWithParams, getModulePath }
}

const SitesPageView = ({ module, availableFilters }: SitesPageViewProps) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const { navigateWithParams, getModulePath } = useSitesNavigation(module)

  const [activeView, setActiveView] = useState<ViewType>('cards')
  const [activeScoring, setActiveScoring] = useState<ScoringType>(ScoringType.BIODIVERSITY)
  const [hoveredSiteId, setHoveredSiteId] = useState<number>()
  const hoveredCardRef = useRef<number | null>(null)

  const page = parseInt(searchParams.get('page') ?? '1', 10)

  const defaultFilters = useMemo(() => {
    const baseFilters: SitesListParams = { page: 1, page_size: PAGE_SIZE }

    if (module === MODULE.MONITORING) baseFilters.is_in_monitoring = true
    if (module === MODULE.INITIATIVE) baseFilters.is_in_initiative = true
    if (module === MODULE.IMPACT) baseFilters.is_in_impact = true

    return baseFilters
  }, [module])

  const [filters, setFilters] = useState<SitesListParams>(defaultFilters)

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      search: searchParams.get('search') ?? undefined,
      ordering: searchParams.get('ordering') ?? undefined,
      page: isNaN(page) ? 1 : page,
      page_size: PAGE_SIZE,
    }))
  }, [location.search, page])

  const { data: dataSites, isLoading: isLoadingDataSites } = useQuery({
    queryKey: [getSitesListQueryKey(filters)],
    queryFn: () => sitesList(filters, undefined),
  })

  const { data: lightDataSites, isLoading: isLoadingLightDataSites } = useQuery({
    queryKey: [getSitesIdNameCenterListQueryKey],
    queryFn: () => sitesIdNameCenterList(),
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value
    const queryParams: SitesListParams = {
      ...defaultFilters,
      page: 1,
      ...(searchValue && { search: searchValue }), // Add search only if it has a value
      ...(filters.ordering && { ordering: filters.ordering }),
    }
    navigateWithParams(queryParams)
  }

  const handleSortByScore = (sortType: string, scoreType: 'pesticides' | 'biodiversity') => {
    const orderingMap = {
      pesticides: {
        [SortType.ASC]: 'stats_pesticides__ecoscore',
        [SortType.DESC]: '-stats_pesticides__ecoscore',
      },
      biodiversity: {
        [SortType.ASC]: 'stats_biodiversity__biodiversity_score',
        [SortType.DESC]: '-stats_biodiversity__biodiversity_score',
      },
    }

    const ordering = orderingMap[scoreType]?.[sortType]
    const queryParams: SitesListParams = {
      ...defaultFilters,
      page: 1,
      ...(ordering && { ordering }), // Add ordering only if it has a value
      ...(filters.search && { search: filters.search }), // Add search only if it has a value
    }

    navigateWithParams(queryParams)
  }

  const getAvailableFiltersForView = () => {
    if (activeView !== 'cards') return []

    return activeScoring === ScoringType.BIODIVERSITY &&
      availableFilters?.includes(AVAILABLE_FILTERS.SCORE_BIODIVERSITY)
      ? [AVAILABLE_FILTERS.SCORE_BIODIVERSITY]
      : availableFilters?.includes(AVAILABLE_FILTERS.SCORE_PESTICIDES)
        ? [AVAILABLE_FILTERS.SCORE_PESTICIDES]
        : []
  }

  if (!user) return <Loader />

  const currentFilters = getAvailableFiltersForView()

  return (
    <div className="pl-11">
      <BreadcrumbComponent
        breadcrumbItems={[
          {
            label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoapps),
            href: '/',
          },
          {
            label: t(i18nKeys.global.common.module[module]),
            href: getModulePath(),
          },
        ]}
        currentPage={t(i18nKeys.beeoinitiative.common.breadcrumb.sites)}
      />

      <div className="flex flex-col">
        <Tabs defaultValue="cards" onValueChange={(value) => setActiveView(value as ViewType)}>
          <div className="flex items-center justify-start pr-11">
            <div className="flex w-full items-center justify-between">
              <PageHeader>
                <PageHeaderHeading>{t(i18nKeys.beeoinitiative.sites.title)}</PageHeaderHeading>
                {t(i18nKeys.beeoinitiative.sites.total)}: {lightDataSites?.length ?? 0}
              </PageHeader>

              <div className="flex items-center justify-center space-x-5 pt-6">
                {(availableFilters?.includes(AVAILABLE_FILTERS.SCORE_PESTICIDES) ||
                  availableFilters?.includes(AVAILABLE_FILTERS.SCORE_BIODIVERSITY)) && (
                  <div className="flex flex-col gap-2">
                    <Tabs value={activeScoring} onValueChange={(value) => setActiveScoring(value as ScoringType)}>
                      <TabsList className="flex">
                        <TabsTrigger
                          disabled={!user.features?.includes(FeatureType.BIODIVERSITY)}
                          value={ScoringType.BIODIVERSITY}
                          className={cn(
                            'data-[state=active]:bg-biodivTealSevenHundred data-[state=active]:text-white',
                            'data-[state=inactive]:bg-muted data-[state=inactive]:text-black'
                          )}
                        >
                          Biodiversity
                        </TabsTrigger>
                        <TabsTrigger
                          disabled={!user.features?.includes(FeatureType.PESTICIDES)}
                          value={ScoringType.PESTICIDES}
                          className={cn(
                            'data-[state=active]:bg-biodivTealSevenHundred data-[state=active]:text-white',
                            'data-[state=inactive]:bg-muted data-[state=inactive]:text-black'
                          )}
                        >
                          Pesticides
                        </TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </div>
                )}

                <SitesFilters
                  user={user}
                  availableFilters={currentFilters}
                  onInputChange={handleInputChange}
                  onSortByBiodiversityScore={(sortType) => handleSortByScore(sortType, 'biodiversity')}
                  onSortByPesticidesScore={(sortType) => handleSortByScore(sortType, 'pesticides')}
                />

                <div>
                  <TabsList className="flex rounded-full">
                    <TabsTrigger
                      value="cards"
                      className="text-black data-[state=active]:rounded-full data-[state=active]:bg-black data-[state=active]:text-white"
                    >
                      <Icons.MapPin />
                    </TabsTrigger>
                    <TabsTrigger
                      value="list"
                      className="text-black data-[state=active]:rounded-full data-[state=active]:bg-black data-[state=active]:text-white"
                    >
                      <Icons.Grid2X2 />
                    </TabsTrigger>
                  </TabsList>
                </div>
              </div>
            </div>
          </div>

          <TabsContent value="cards">
            <div className="flex justify-center gap-x-5">
              <SitesCards
                module={module}
                setHoveredSiteId={setHoveredSiteId}
                isLoadingDataSites={isLoadingDataSites}
                user={user}
                filters={filters}
                paginatedData={dataSites}
                activeScoring={activeScoring}
              />
              <SitesMapContainer
                user={user}
                module={module}
                sites={lightDataSites ?? []}
                hoveredSiteId={hoveredSiteId}
                hoveredCardRef={hoveredCardRef}
                scoringType={activeScoring}
                isLoading={isLoadingLightDataSites}
              />
            </div>
          </TabsContent>

          <TabsContent value="list" className="pb-4 pr-11">
            {activeScoring === ScoringType.BIODIVERSITY && (
              <SitesTableBiodiversity data={dataSites} accountId={user.account_id} showActions={false} />
            )}
            {activeScoring === ScoringType.PESTICIDES && (
              <SitesTablePesticides data={dataSites} accountId={user.account_id} showActions={false} />
            )}
          </TabsContent>
        </Tabs>
      </div>
    </div>
  )
}

export default SitesPageView
