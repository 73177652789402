import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { H2, H3, LI, P, UL } from '@/components/typography'

const BiodiversityRisk = () => {
  const { t } = useTranslation()
  return (
    <div className="py-4">
      <H2>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.title)}</H2>
      <P>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.description)}</P>

      <H3>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.scope.title)}</H3>
      <P>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.scope.description)}</P>

      <H3>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.score.title)}</H3>
      <P>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.score.p1)}</P>
      <P>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.score.p2)}</P>
      <P>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.score.p3)}</P>
      <H3>
        <P>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.title)}</P>
      </H3>

      <P>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.description)}</P>
      <span>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.subtitle)}</span>

      <UL className="list-none">
        <LI>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.distances.office)}</LI>
        <LI>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.distances.manufacturing)}</LI>
        <LI>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.distances.extraction)}</LI>
        <LI>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.distances.agricultural)}</LI>
      </UL>

      <H3>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.datasets.title)}</H3>

      <P> {t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.datasets.description)}</P>

      <UL>
        <LI>
          <a
            href="https://www.eea.europa.eu/en/datahub/datahubitem-view/6fc8ad2d-195d-40f4-bdec-576e7d1268e4"
            target="_blank"
            rel="noreferrer"
            className="text-blue-900 underline"
          >
            Natura 2000 data - the European network of protected sites
          </a>
          <span className="text-sm">
            {''} {t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.datasets.description2)}
          </span>
        </LI>
        <LI>
          <a
            href="https://www.eea.europa.eu/en/datahub/datahubitem-view/f60cec02-6494-4d08-b12d-17a37012cb28"
            target="_blank"
            rel="noreferrer"
            className="text-blue-900 underline"
          >
            Global Biodiversity Information Facility (GBIF)
          </a>
        </LI>
        <LI>
          <a
            href="https://doi.org/10.2909/616ef48f-7196-4e30-b201-6c97808fa68a"
            target="_blank"
            rel="noreferrer"
            className="text-blue-900 underline"
          >
            Nationally designated areas (CDDA)
          </a>
        </LI>
      </UL>
    </div>
  )
}

export default BiodiversityRisk
