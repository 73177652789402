import { Link } from 'react-router-dom'

import { cn } from '@/lib/utils'
import { Card } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'

interface IndicatorCardProps {
  title: string
  icon?: React.ReactNode
  value: number | string
  bgColor?: string
  linkText: string
  to?: string
  width?: string
  skeletonSize: string
  isLoading?: boolean
}

export const IndicatorCard = ({
  title,
  icon,
  value,
  bgColor,
  linkText,
  to,
  width,
  skeletonSize = 'h-[210px]', // default height
  isLoading,
}: IndicatorCardProps) => {
  if (isLoading) {
    return <Skeleton className={skeletonSize} />
  }
  return (
    <Card
      className={cn(
        'flex max-h-[210px] flex-col justify-evenly space-y-2 sm:p-8 md:p-8 lg:p-8 xl:p-8 2xl:px-10 2xl:pb-9 2xl:pt-10',
        bgColor,
        width
      )}
    >
      <div className="flex  items-center justify-between  text-xl font-semibold">
        {title}
        {icon}
      </div>
      <div className="flex flex-col items-start justify-between space-y-2">
        <p className="text-6xl font-semibold">{value}</p>
        {to ? (
          <div>
            <Link to={to} className="text-sm underline">
              {linkText}
            </Link>
          </div>
        ) : (
          <p>{linkText}</p>
        )}
      </div>
    </Card>
  )
}

export default IndicatorCard
