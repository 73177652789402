import { i18nKeys } from '@/locales/keys'
import { Info } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { ScoringType } from '@/types/scoring-type'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

interface SitesRiskLegendProps {
  scoringType: ScoringType
}

const SitesRiskLegend = ({ scoringType }: SitesRiskLegendProps) => {
  const { t } = useTranslation()

  const biodivLegend = [
    {
      color: '#4CAF50',
      i18Key: i18nKeys.beeoimpact.site.dashboard.scoring.biodiversity.legend.low,
    },
    {
      color: '#FF9800',
      i18Key: i18nKeys.beeoimpact.site.dashboard.scoring.biodiversity.legend.medium,
    },
    {
      color: '#F44336',
      i18Key: i18nKeys.beeoimpact.site.dashboard.scoring.biodiversity.legend.high,
    },
  ]

  const pesticidesLegend = [
    {
      color: '#4CAF50',
      i18Key: i18nKeys.beeoimpact.site.dashboard.scoring.pesticide.legend.low,
    },
    {
      color: '#FF9800',
      i18Key: i18nKeys.beeoimpact.site.dashboard.scoring.pesticide.legend.medium,
    },
    {
      color: '#F44336',
      i18Key: i18nKeys.beeoimpact.site.dashboard.scoring.pesticide.legend.high,
    },
  ]

  return (
    <div className="l:pr-0 mx-auto w-full max-w-[1400px] pr-4 xl:pr-0">
      <div className="flex flex-col items-start gap-4 rounded-lg border border-border bg-muted/50 p-4 sm:flex-row sm:items-center">
        <span className="font-bold">{t(i18nKeys.beeoimpact.sites.top.risk)} :</span>

        <div className="flex flex-col gap-3 sm:flex-row sm:gap-4">
          {(scoringType === ScoringType.BIODIVERSITY ? biodivLegend : pesticidesLegend).map((item, index) => (
            <div key={index} className="flex items-center gap-2">
              <div className="flex items-center gap-1">
                <div className="size-3 rounded-full" style={{ backgroundColor: item.color }} />
                <span className="text-sm sm:text-base">{t(item.i18Key)}</span>
              </div>
            </div>
          ))}
        </div>

        <div className="flex items-center gap-1 sm:ml-auto">
          <span className="text-sm">{t(i18nKeys.global.common.buttons.learnMore)}</span>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex justify-end">
                  <Info className="size-5" />
                </div>
              </TooltipTrigger>
              <TooltipContent data-side="bottom" className="w-96">
                {scoringType === ScoringType.BIODIVERSITY ? (
                  <p className="text-sm">{t(i18nKeys.beeoimpact.site.dashboard.scoring.biodiversity.description)}</p>
                ) : (
                  <p className="text-sm">{t(i18nKeys.beeoimpact.site.dashboard.scoring.pesticide.description)}</p>
                )}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>
  )
}

export default SitesRiskLegend
