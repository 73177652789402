import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import EncoreMethodology from '@/components/beeoimpact/methodology/encore-methodology'
import Habitat from '@/components/beeoimpact/methodology/habitat'
import HabitatSensitivity from '@/components/beeoimpact/methodology/habitat-sensitivity'
import SpectralIndices from '@/components/beeoimpact/methodology/spectral-indices'
import { H2, H3, LI, P, UL } from '@/components/typography'

const GeneralAnalysis = () => {
  const { t } = useTranslation()
  return (
    <div className="py-4">
      <H2> {t(i18nKeys.beeoimpact.methodology.generalAnalysis.title)}</H2>
      <P>{t(i18nKeys.beeoimpact.methodology.generalAnalysis.description)}</P>

      <H3>{t(i18nKeys.beeoimpact.methodology.generalAnalysis.data.scope.title)}</H3>
      <P>{t(i18nKeys.beeoimpact.methodology.generalAnalysis.data.scope.description)}</P>

      <H3>{t(i18nKeys.beeoimpact.methodology.generalAnalysis.data.datasets.title)}</H3>
      <P> {t(i18nKeys.beeoimpact.methodology.generalAnalysis.data.datasets.description)}</P>

      <UL>
        <LI>
          <a
            href="https://land.copernicus.eu/en/products/corine-land-cover"
            target="_blank"
            rel="noreferrer"
            className="text-blue-900 underline"
          >
            CORINE Land Cover — Copernicus Land Monitoring Service
          </a>
        </LI>
        <LI>
          <a href="https://www.gbif.org/" target="_blank" rel="noreferrer" className="text-blue-900 underline">
            Global Biodiversity Information Facility (GBIF)
          </a>
        </LI>
        <LI>
          ENCORE Partners (Global Canopy, UNEP FI, and UNEP-WCMC) (year). ENCORE: Exploring Natural Capital
          Opportunities, Risks and Exposure. 10/2024, Cambridge, UK: the ENCORE Partners.
          <p>
            Available at:{' '}
            <a href="https://encorenature.org" target="_blank" rel="noreferrer" className="text-blue-900 underline">
              https://encorenature.org
            </a>
          </p>
          <p>
            DOI:{' '}
            <a
              href="https://doi.org/10.34892/dz3x-y059."
              target="_blank"
              rel="noreferrer"
              className="text-blue-900 underline"
            >
              https://doi.org/10.34892/dz3x-y059
            </a>
          </p>
        </LI>
        <LI>
          <p> 'Copernicus Sentinel data 2020-2025' for Sentinel data</p>
        </LI>
      </UL>

      <H3>{t(i18nKeys.beeoimpact.methodology.generalAnalysis.section.scores.title)}</H3>
      <SpectralIndices />
      <EncoreMethodology />
      <Habitat />
      <HabitatSensitivity />
    </div>
  )
}

export default GeneralAnalysis
