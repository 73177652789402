export const pressureValue = {
  VH: 7,
  H: 6,
  M: 5,
  L: 4,
  VL: 3,
  ND: 2,
  N: 1,
}

export const pressureValueToLabel = {
  7: 'VH',
  6: 'H',
  5: 'M',
  4: 'L',
  3: 'VL',
  2: 'ND',
  1: 'N',
}

export const pressureValueToColor = {
  7: '#e31a1c', // Very High - Dark red
  6: '#fc4e2a', // High - Red-orange
  5: '#fd8d3c', // Medium - Orange
  4: '#feb24c', // Low - Light orange
  3: '#fed976', // Very Low - Yellow
  2: '#cccccc', // No Data - Gray
  1: '#ffffff', // None - White
}
