import { Info } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

interface CardAnalyticsProps {
  description?: React.ReactNode
  icon?: React.ReactNode
  value: number | string
  label: string
  isLoading?: boolean
  definition: string
  bgColor?: string
}

const CardsAnalytics = ({
  description,
  icon,
  label,
  value,
  isLoading,
  definition,
  bgColor = '',
}: CardAnalyticsProps) => {
  if (isLoading) {
    return <Skeleton className="h-[232px] w-full" />
  }

  return (
    <Card className={`${bgColor} h-full space-y-9`}>
      <CardHeader className="h-10">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <p className="text-xl font-bold">{label}</p>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Info className={cn('size-4')} />
                </TooltipTrigger>
                <TooltipContent data-side="bottom" className="w-96">
                  {description}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          <div className="text-red-500">{icon}</div>
        </div>
      </CardHeader>
      <CardContent className="flex flex-col space-y-2">
        <p className="text-6xl font-bold">{value}</p>
        <p className="text-sm">{definition}</p>
      </CardContent>
    </Card>
  )
}

export default CardsAnalytics
