import {
  getSitesListQueryKey,
  getSitesRetrieveQueryKey,
  sitesList,
  sitesRetrieve,
} from '@/client/backend/api/sites/sites'
import { Site, SitesListParams } from '@/client/backend/models'
import { Initiative } from '@/client/backend/models/initiative'
import { i18nKeys } from '@/locales/keys'
import {
  CostInputField,
  DateSelectionField,
  InitiativeCard,
  SiteSelectionField,
} from '@/pages/beeoinitiative/components/initiative-form-fields'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { CalendarPlus, Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { Separator } from '@/components/ui/separator'
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet'

export const FormSchema = z.object({
  date: z.date(),
  site: z.number(),
  cost: z.coerce.number().int().positive(),
})

type FormData = z.infer<typeof FormSchema>

interface InitiativeFormProps {
  initiative: Initiative
  currentSiteId?: number
  open: boolean
  setOpen: (value: boolean) => void
  onSubmit: (data: FormData) => void
  defaultValues?: Partial<FormData>
  title: string
  submitButtonText: string
  isSubmitting?: boolean
}

export const InitiativeForm = ({
  initiative,
  currentSiteId,
  open,
  setOpen,
  onSubmit,
  defaultValues,
  title,
  submitButtonText,
  isSubmitting = false,
}: InitiativeFormProps) => {
  const { t } = useTranslation()
  const params: SitesListParams = { is_in_initiative: true, page_size: 6 }

  const siteRetrieve = useQuery({
    queryKey: [getSitesRetrieveQueryKey(currentSiteId?.toString() ?? ''), { include: 'initiative_score' }],
    queryFn: () => sitesRetrieve(currentSiteId?.toString() ?? '', { include: 'initiative_score' }),
    enabled: !!currentSiteId,
  })

  const siteListData = useQuery({
    queryKey: [getSitesListQueryKey(params)],
    queryFn: () => sitesList(params),
  })

  const form = useForm<FormData>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  })

  const handleSubmit = (data: FormData) => {
    if (!initiative) return null
    onSubmit(data)
  }

  const siteList =
    currentSiteId && siteRetrieve.data
      ? [{ label: siteRetrieve.data.properties?.name, value: siteRetrieve.data.id }]
      : (siteListData?.data?.results?.features?.map((site: Site) => ({
          label: site.properties?.name,
          value: site.id,
        })) ?? [])

  const siteListIsLoading = currentSiteId ? siteRetrieve.isLoading : siteListData.isLoading

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetContent className="min-w-[470px]">
        <SheetHeader>
          <SheetTitle className="mb-4 flex items-end gap-3">
            <CalendarPlus className="size-8 text-biodivLimeFiveHundred" />
            <span className="text-xl font-semibold">{title}</span>
          </SheetTitle>
          <Separator />
        </SheetHeader>

        <div className="mt-9 max-h-[calc(100vh-160px)] overflow-y-auto px-3">
          <p className="font-semibold">
            {t(i18nKeys.beeoinitiative.initiativeDetailPage.addInitiative.selectedInitiative)}
          </p>
          <div className="mb-10 mt-3 max-w-[357px]">
            <InitiativeCard initiative={initiative} />
          </div>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
              {!siteListIsLoading && <SiteSelectionField siteList={siteList} form={form} defaultSite={currentSiteId} />}
              <DateSelectionField form={form} />
              <CostInputField form={form} showDisabledInput={false} />

              <Separator />

              <Button
                variant="outline"
                type="submit"
                disabled={isSubmitting}
                className="w-full border-black font-semibold"
              >
                {isSubmitting && <Loader2 className="mr-2 size-4 animate-spin" />}
                {submitButtonText}
              </Button>
            </form>
          </Form>
        </div>
      </SheetContent>
    </Sheet>
  )
}
