import { Card } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'

interface ColorfulCardProps {
  bgColor: string
  width: string
  icon: React.ReactNode
  percentage: number
  text: React.ReactNode
  height: string
  border: string
  isLoadingStats: boolean
  skeletonClassName: string
}
const ColorfulCard = ({
  bgColor,
  width,
  icon,
  percentage,
  text,
  height,
  border,
  isLoadingStats,
  skeletonClassName,
}: ColorfulCardProps) => {
  if (isLoadingStats) {
    return <Skeleton className={skeletonClassName} />
  }
  return (
    <Card className={`${bgColor} ${width} ${height} ${border} flex items-center space-x-5 px-5`}>
      <div>{icon}</div>
      <p className="space-x-1 font-bold md:text-lg xl:text-base 2xl:text-lg">
        <span>{percentage}</span>
        <span>%</span>
      </p>
      <Separator orientation="vertical" className="h-4" />
      <p className="text-sm">{text}</p>
    </Card>
  )
}

export default ColorfulCard
