import { useEffect, useRef } from 'react'
import { ActionTaken, Site } from '@/client/backend/models'
import FormClosePlannedAction from '@/pages/beeoinitiative/site-detail/form-close-planned-action'
import { Link } from 'react-router-dom'

import { dateUpdateFormat } from '@/lib/date-update-format'
import { DEFAULT_INITIATIVE_CATEGORY, INITIATIVE_CATEGORIES } from '@/lib/initiative'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Card } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import CategoryIcon from '@/components/beeoinitiative/category-icon'
import { Icons } from '@/components/icons'

interface TakenPlannedInitiativesProps {
  title: string | number
  footer: string
  button: boolean
  initiativeList?: ActionTaken[]
  seeMoreCard?: boolean
  setSeeMoreCard: (value: boolean) => void
  site: Site
  isLoading: boolean
}

interface InitiativeItemProps {
  initiative: ActionTaken
  site: Site
  button: boolean
  isLoading: boolean
}

const InitiativeItem = ({ initiative, site, button, isLoading }: InitiativeItemProps) => {
  const initiativeCategory = initiative.initiative.category
    ? INITIATIVE_CATEGORIES[initiative.initiative.category]
    : DEFAULT_INITIATIVE_CATEGORY

  const formattedDate =
    initiative.status === 'DONE'
      ? dateUpdateFormat(initiative.date_done ?? null)
      : initiative.status === 'PENDING'
        ? dateUpdateFormat(initiative.date_start ?? null)
        : null

  const budget =
    initiative.status === 'DONE'
      ? (initiative.spent_budget ?? 0)
      : initiative.status === 'PENDING'
        ? (initiative.planned_cost ?? 0)
        : 0

  return (
    <div className="flex items-center justify-between">
      <div>
        <div className="flex flex-row items-center pb-3" key={initiative.id}>
          <div
            className="flex items-center justify-center rounded-xl border"
            style={{
              width: '26px',
              height: '26px',
              borderColor: initiativeCategory.color,
              backgroundColor: `${initiativeCategory.color}10`,
            }}
          >
            {initiativeCategory.iconName && (
              <CategoryIcon name={initiativeCategory.iconName} color={initiativeCategory.color} className="size-4" />
            )}
          </div>
          <div className="flex flex-col justify-between px-2 pt-[2px]">
            {site && (
              <Link
                to={getPath(Routes.BEEOINITIATIVE_INITIATIVE_DETAIL_BY_SITE_BY_INITIATIVE_BY_ACTIONTAKEN, {
                  params: {
                    siteId: site.id,
                    initiativeId: initiative.initiative_id,
                    actionTakenId: initiative.id,
                  },
                })}
                className="text-lg font-bold leading-5 text-gray-800"
              >
                {initiative.initiative.short_name}
              </Link>
            )}
          </div>
        </div>
        <div className="flex space-x-4 text-sm">
          {formattedDate && <span>{formattedDate}</span>}
          <Separator className="self-center py-1.5" orientation="vertical" />
          <div className="flex items-center space-x-2">
            <Icons.Leaf className="size-4 text-biodivTealSevenHundred" />
            <span>{initiative.initiative.impact} pts</span>
          </div>
          <Separator className="self-center bg-separatorGray py-1.5" orientation="vertical" />
          <span>{budget} €</span>
        </div>
      </div>
      {button && (
        <FormClosePlannedAction
          initiative={initiative.initiative}
          actionTaken={initiative}
          site={site}
          isLoading={isLoading}
        />
      )}
    </div>
  )
}

const TakenPlannedInitiativesCard = ({
  title,
  footer,
  button,
  initiativeList,
  seeMoreCard = false,
  setSeeMoreCard,
  site,
  isLoading,
}: TakenPlannedInitiativesProps) => {
  // Define variables for conditional styles
  const cardMaxHeight = seeMoreCard ? '800px' : '435px'
  const contentMaxHeight = seeMoreCard ? '500px' : '281px'
  const overflowStyle = seeMoreCard ? 'scroll' : 'hidden'
  // Create a ref for the content container
  const contentRef = useRef<HTMLDivElement>(null)

  // Scroll to top when seeMoreCard is toggled
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [seeMoreCard])

  if (isLoading) {
    return <Skeleton className="h-96 bg-blue-500" />
  }
  return (
    <Card
      className={`md:mb-4 md:px-8 md:py-9 lg:mb-4 lg:w-full xl:mb-0 2xl:mb-0 2xl:px-10 2xl:py-9`}
      style={{ maxHeight: cardMaxHeight }}
    >
      <div className="flex space-x-2 pb-6 text-xl font-semibold">
        <p className="flex flex-col">{title}</p>
      </div>
      <Separator className="bg-separatorGray" />
      <div
        className={`flex flex-col space-y-8 px-4 py-8`}
        style={{ height: contentMaxHeight, overflowY: overflowStyle }}
        ref={contentRef}
      >
        {initiativeList?.map((initiative) => (
          <InitiativeItem
            key={initiative.id}
            initiative={initiative}
            site={site}
            button={button}
            isLoading={isLoading}
          />
        ))}
      </div>
      <Separator className="bg-separatorGray" />
      <div
        onClick={() => setSeeMoreCard(!seeMoreCard)}
        className="flex cursor-pointer items-center justify-center py-4 underline"
      >
        {footer}
      </div>
    </Card>
  )
}

export default TakenPlannedInitiativesCard
