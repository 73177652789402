import { Dispatch, SetStateAction, useCallback } from 'react'
import { ProbablePesticide } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { Droplet } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { SpeciesType } from '@/types/species-type'
import GlobalEcoscoreColor from '@/lib/global-ecoscore'
import { toTitleCase } from '@/lib/string'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'
import { SpeciesIcon } from '@/components/icons-components/active-matters-icons'

interface PesticidesTableProps<T> {
  activeMatters: T[]
  setSelectedPesticide?: Dispatch<SetStateAction<ProbablePesticide>>
  selectedPesticide?: ProbablePesticide
  hideActiveMatterColumn?: boolean
  columns?: ColumnDef<T>[]
}

const PesticidesTable = <T extends ProbablePesticide>({
  activeMatters,
  setSelectedPesticide,
  selectedPesticide,
  hideActiveMatterColumn = false,
}: PesticidesTableProps<T>) => {
  const { t } = useTranslation()

  const columns: ColumnDef<T>[] = (() => {
    const cols: ColumnDef<T>[] = []

    if (!hideActiveMatterColumn) {
      cols.push({
        accessorKey: 'activeMatter',
        id: 'activeMatter',
        header: ({ column }) => (
          <DataTableColumnHeader column={column}>
            {t(i18nKeys.beeoimpact.pesticidesTable.activeMatters.table.header.activeMatter)}
          </DataTableColumnHeader>
        ),
        cell: ({ row }) => (
          <span className="font-medium underline underline-offset-2">{toTitleCase(row.original.name)}</span>
        ),
        enableSorting: false,
        enableHiding: false,
      })
    }

    cols.push({
      accessorKey: 'waterRisk',
      id: 'waterRisk',
      header: ({ column }) => (
        <DataTableColumnHeader column={column}>
          {t(i18nKeys.beeoimpact.pesticidesTable.activeMatters.table.header.waterRisk)}
        </DataTableColumnHeader>
      ),
      cell: ({ row }) => {
        const isWaterRisk = Boolean(row.original.ecoscores.ecoscore_water)
        const color = isWaterRisk ? 'text-red-500 bg-red-100' : 'text-green-500 bg-green-100'
        return (
          <div className={`inline-flex size-8 items-center justify-center rounded-full ${color}`}>
            <Droplet className={`size-5 ${color}`} />
          </div>
        )
      },
      size: 1,
      enableSorting: false,
      enableHiding: false,
    })

    cols.push({
      accessorKey: 'toxicity',
      id: 'toxicity',
      header: ({ column }) => (
        <DataTableColumnHeader column={column}>
          {t(i18nKeys.beeoimpact.pesticidesTable.activeMatters.table.header.toxicity)}
        </DataTableColumnHeader>
      ),
      cell: ({ row }) => <GlobalEcoscoreColor ecoscore_global={row.original.ecoscores.ecoscore_global} />,
      enableSorting: false,
      enableHiding: false,
    })

    // Define species columns to avoid repetition.
    const speciesColumnsData = [
      {
        id: 'bees',
        species: SpeciesType.Bees,
        translationKey: i18nKeys.beeoimpact.pesticidesTable.speciesGroups.bees,
      },
      {
        id: 'birds',
        species: SpeciesType.Birds,
        translationKey: i18nKeys.beeoimpact.pesticidesTable.speciesGroups.birds,
      },
      {
        id: 'fishes',
        species: SpeciesType.Fishes,
        translationKey: i18nKeys.beeoimpact.pesticidesTable.speciesGroups.fishes,
      },
      {
        id: 'mammals',
        species: SpeciesType.Mammals,
        translationKey: i18nKeys.beeoimpact.pesticidesTable.speciesGroups.mammals,
      },
      {
        id: 'worms',
        species: SpeciesType.Worms,
        translationKey: i18nKeys.beeoimpact.pesticidesTable.speciesGroups.worms,
      },
      {
        id: 'soil',
        species: SpeciesType.Soil,
        translationKey: i18nKeys.beeoimpact.pesticidesTable.speciesGroups.soil,
      },
    ]

    speciesColumnsData.forEach(({ id, species, translationKey }) => {
      cols.push({
        accessorKey: id,
        id,
        header: ({ column }) => <DataTableColumnHeader column={column}>{t(translationKey)}</DataTableColumnHeader>,
        cell: ({ row }) => (
          <div className="flex items-center">
            <span className="mr-2">
              <SpeciesIcon value={row.original.ecoscores[`ecoscore_${id}`]} species={species} />
            </span>
          </div>
        ),
        enableSorting: false,
        enableHiding: false,
      })
    })

    return cols
  })()

  const table = useReactTable({
    data: activeMatters,
    columns,
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getSortedRowModel(),
  })

  const handleRowClick = useCallback(
    (pesticide: ProbablePesticide) => {
      if (setSelectedPesticide) {
        setSelectedPesticide(pesticide)
      }
      window.scrollTo({ top: 200, behavior: 'smooth' })
    },
    [setSelectedPesticide]
  )

  return (
    <div className="flex w-full flex-col justify-between pb-5">
      <div className="w-full">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} className="w-24">
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className={`${row.original === selectedPesticide ? 'bg-red-50' : ''} cursor-pointer`}
                  onClick={() => handleRowClick(row.original)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="w-8">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  {t(i18nKeys.global.common.datatable.emptyResults)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default PesticidesTable
