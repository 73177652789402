import { useEffect, useMemo, useState } from 'react'
import { getInitiativesListQueryKey, initiativesList } from '@/client/backend/api/initiatives/initiatives'
import {
  getSitesResponsesListQueryKey,
  getSitesRetrieveQueryKey,
  sitesResponsesList,
  sitesRetrieve,
} from '@/client/backend/api/sites/sites'
import { Initiative } from '@/client/backend/models/initiative'
import { i18nKeys } from '@/locales/keys'
import FormCreateInitiative from '@/pages/beeoinitiative/form-create-initiative'
import { useQuery } from '@tanstack/react-query'
import { ChevronDown, ChevronUp, Search } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  COSTS,
  filterInitiativeOnSiteResponses,
  IMPACTS,
  INITIATIVE_CATEGORIES,
  PERIODS,
  SITE_TYPES,
} from '@/lib/initiative'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import BreadcrumbComponent from '@/components/breadcrumb-component'
import TextField from '@/components/form/text-field'
import { Icons } from '@/components/icons'
import { PageHeaderHeading } from '@/components/page-header'
import { H3 } from '@/components/typography'

import InitiativeCard from './initiative-card'
import InitiativesFilters from './initiatives-filters'

const ChevronToggle = ({ isOpen }) => (isOpen ? <ChevronUp className="w-4" /> : <ChevronDown className="w-4" />)

const InitiativesPage = () => {
  const { t } = useTranslation()
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)
  const { siteId } = useParams<{ siteId?: string }>()
  const [initiativesListValue, setInitiativesListValue] = useState<Initiative[]>([])

  const { data: initiatives, isLoading: isLoadingInitiatives } = useQuery({
    queryKey: [getInitiativesListQueryKey()],
    queryFn: () => initiativesList(),
  })

  const { data: site } = useQuery({
    queryKey: [getSitesRetrieveQueryKey(siteId!)],
    queryFn: () => sitesRetrieve(siteId!, undefined),
    enabled: !!siteId,
  })

  const { data: siteResponses } = useQuery({
    queryKey: [getSitesResponsesListQueryKey(siteId!)],
    queryFn: () => sitesResponsesList(siteId!, undefined),
    enabled: !!siteId,
  })

  useEffect(() => {
    if (initiatives) {
      setInitiativesListValue(initiatives)
    }
  }, [initiatives])

  const categoryToFilter = (category) =>
    Object.keys(category).map((key) => ({ value: key, checked: false, ...category[key] }))
  const [filters, setFilters] = useState({
    name: '',
    impact: categoryToFilter(IMPACTS),
    cost: categoryToFilter(COSTS),
    site_type: categoryToFilter(SITE_TYPES),
    category: categoryToFilter(INITIATIVE_CATEGORIES),
    time_line: categoryToFilter(PERIODS),
  })

  const filterValues = (filterName) => (initiative) => {
    const checkedValues = filters[filterName].filter((f) => f.checked)
    if (checkedValues.length) {
      return checkedValues.some((filter) => initiative[filterName] === filter.value)
    }
    return true
  }

  const filteredInitiatives = useMemo(
    () =>
      initiativesListValue
        ?.filter((initiative) => {
          if (siteResponses) {
            return filterInitiativeOnSiteResponses(initiative, siteResponses)
          }
          return true
        })
        .filter((initiative) => {
          if (filters.name) {
            return (
              initiative.full_name?.toLowerCase().includes(filters.name.toLowerCase()) ||
              initiative.short_name?.toLowerCase().includes(filters.name.toLowerCase()) ||
              initiative.category?.toLowerCase().includes(filters.name.toLowerCase())
            )
          }
          return true
        })
        .filter((initiative) => {
          if (initiative.time_line === 'All') {
            return true
          }
          return filterValues('time_line')(initiative)
        })
        .filter(filterValues('impact'))
        .filter(filterValues('cost'))
        .filter(filterValues('site_type'))
        .filter(filterValues('category')),
    [initiativesListValue, filters, siteResponses]
  )

  const setName = (name) => setFilters({ ...filters, name })

  const getBreadcrumbItems = () => {
    const breadcrumbItems = [
      {
        label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoapps),
        href: '/',
      },
      {
        label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoinitiative),
      },
    ]

    if (site) {
      breadcrumbItems.push({
        label: t(i18nKeys.beeoinitiative.common.breadcrumb.sites),
        href: getPath(Routes.BEEOINITIATIVE_SITE),
      })
      breadcrumbItems.push({
        label: `site ${site?.properties?.name}`,
        href: getPath(Routes.BEEOINITIATIVE_SITE, { params: { siteId: site.id } }),
      })
    }
    return breadcrumbItems
  }

  const handleCreateInitiative = (initiative: Initiative) => {
    setInitiativesListValue((prevInitiatives) => [...prevInitiatives, initiative])
  }

  return (
    <div className="flex size-full flex-1 flex-col gap-y-2  px-11 pb-11">
      <BreadcrumbComponent
        breadcrumbItems={getBreadcrumbItems()}
        currentPage={t(i18nKeys.beeoinitiative.common.breadcrumb.initiatives)}
      />
      <div>
        <PageHeaderHeading>
          {site ? (
            <p>
              {t(i18nKeys.beeoinitiative.initiativesPage.page.title)} {site?.properties?.name}
            </p>
          ) : (
            t(i18nKeys.beeoinitiative.initiativesPage.page.titleAll)
          )}
        </PageHeaderHeading>
      </div>
      <div className="mb-3 flex flex-row items-center justify-between">
        {isLoadingInitiatives ? (
          <Skeleton className="h-7 w-20" />
        ) : (
          <div className="text-lg">
            {t(i18nKeys.beeoinitiative.initiativesPage.page.total)}: {filteredInitiatives?.length}
          </div>
        )}
        <div className="flex flex-row gap-4 self-end">
          <TextField
            className="sm:w-96"
            value={filters.name}
            onChange={setName}
            placeholder={t(i18nKeys.beeoinitiative.initiativesPage.page.searchInitiative)}
            startIcon={<Search className="size-5 text-gray-400" aria-hidden="true" />}
          />
          <Button
            variant="outline"
            className="flex flex-row items-center gap-1"
            onClick={() => setIsFiltersOpen(!isFiltersOpen)}
          >
            <span>{t(i18nKeys.beeoinitiative.initiativesPage.page.filterInitiatives)}</span>
            <ChevronToggle isOpen={isFiltersOpen} />
          </Button>
        </div>
      </div>

      {isFiltersOpen && (
        <>
          <Separator className="mb-6 bg-gray-800" />
          <InitiativesFilters filters={filters} setFilters={setFilters} />
          <Separator className="my-10 bg-gray-800" />
        </>
      )}
      <>
        {filteredInitiatives?.length === 0 ? (
          <div className="mb-[100px] mt-[50px] w-full text-center text-lg text-gray-500">
            {t(i18nKeys.beeoinitiative.initiativesPage.page.noInitiative)}
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-5 xl:grid-cols-3 2xl:grid-cols-4">
            <Card className="flex cursor-pointer items-center rounded-lg hover:shadow-lg">
              <FormCreateInitiative onInitiativeSave={(initiative) => handleCreateInitiative(initiative)}>
                <CardContent className="flex w-full flex-col items-center justify-center text-center">
                  <Icons.PlusCircleIcon className="size-20 border-none text-biodivLimeFiveHundred" strokeWidth={1} />
                  <H3>Créer mon initiative</H3>
                  <p className="mt-6">
                    Nos bio-ingénieurs valideront votre initiative et elle sera ensuite disponible pour votre entreprise
                    sur Beeoinitiative.
                  </p>
                </CardContent>
              </FormCreateInitiative>
            </Card>
            {isLoadingInitiatives
              ? [...Array(12)].map((_, index) => <Skeleton key={index} className="h-[400px] w-[378px]" />)
              : filteredInitiatives?.map((initiative) => (
                  <InitiativeCard
                    initiative={initiative}
                    key={initiative.id}
                    cardHeight="h-[400px]"
                    isLoading={isLoadingInitiatives}
                    site={site}
                  />
                ))}
          </div>
        )}
      </>
    </div>
  )
}

export default InitiativesPage
