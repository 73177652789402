import { Badge } from '@/components/ui/badge'

type ThreatenedSpecies = 'CR' | 'EN'

interface ThreatenedSpeciesBadgesProps {
  category: ThreatenedSpecies
}

const ThreatenedSpeciesBadges = ({ category }: ThreatenedSpeciesBadgesProps) => {
  const colorClasses: Record<ThreatenedSpecies, string> = {
    CR: 'border-red-500 text-red-500',
    EN: 'border-orange-500 text-orange-500',
  }

  return (
    <Badge variant="outline" className={colorClasses[category]}>
      {category}
    </Badge>
  )
}

export default ThreatenedSpeciesBadges
