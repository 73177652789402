/* eslint-disable react-refresh/only-export-components */

/**
 * PrintContext is responsible for switching the content of the application to a printable format.
 *
 * Usage:
 * 1. Pass the printable content to the displayPrintContent function.
 * 2. Set isPrintableContentReady to true to trigger the browser's print function.
 * 3. After the print dialog is closed, the context will reset its state to the initial state.
 *
 * The PrintProvider should be placed at the root of the application to ensure that the printable content is displayed correctly.
 * Some browsers have issues with printing content that are not attached to the root of the DOM.
 */

import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react'

export interface PrintContextType {
  setIsPrintableContentReady: Dispatch<SetStateAction<boolean>>
  displayPrintContent: (printableContent: ReactElement, filename?: string, isPreview?: boolean) => void
}

export const PrintContext = createContext<PrintContextType | null>(null)

export const PrintProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isPrintableContentReady, setIsPrintableContentReady] = useState(false)
  const [printableContent, setPrintableContent] = useState<ReactElement>()
  const [filename, setFilename] = useState<string>()
  const [appTitle, setAppTitle] = useState<string>()
  const [isPreview, setIsPreview] = useState<boolean>(false)

  // Attach the printable content to the DOM
  const displayPrintContent = (printableContent: ReactElement, filename?: string, isPreview = false) => {
    setAppTitle(document.title)
    setFilename(filename)
    setIsPreview(isPreview)
    setPrintableContent(printableContent)
  }

  const resetPrintState = () => {
    setIsPrintableContentReady(false)
    setPrintableContent(undefined)
    document.title = appTitle ?? ''
    setFilename(undefined)
    setAppTitle(undefined)
  }

  // Wait for the printable content to be ready
  useEffect(() => {
    if (printableContent && isPrintableContentReady) {
      window.addEventListener('afterprint', resetPrintState)
      if (filename) document.title = filename
      if (!isPreview) window.print()
    }

    return () => {
      window.removeEventListener('afterprint', resetPrintState)
    }
  }, [printableContent, isPrintableContentReady])

  return (
    <PrintContext.Provider value={{ displayPrintContent, setIsPrintableContentReady }}>
      {printableContent || children}
    </PrintContext.Provider>
  )
}
