import { Habitat, NaceCode } from '@/client/backend/models'
import { ColumnDef } from '@tanstack/react-table'
import { CheckIcon } from 'lucide-react'

import { DataTable } from '@/components/data-table/data-table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'

interface NaceCodeTableProps {
  naceCodes: NaceCode[]
}

const NaceCodeTable = ({ naceCodes }: NaceCodeTableProps) => {
  const filteredNaceCodes = naceCodes.filter((code) => code.name?.toLowerCase() !== 'total')

  const uniqueHabitats = Array.from(
    new Set<string>(
      (naceCodes ?? [])
        .flatMap((item: NaceCode) => item.habitats?.map((h: Habitat) => h.name) ?? [])
        .filter((name): name is string => name !== null && name !== undefined)
    )
  )

  const columns: ColumnDef<NaceCode>[] = [
    {
      accessorKey: 'name',
      header: ({ column }) => <DataTableColumnHeader column={column} children={''} />,
      cell: ({ row }) => <div>{row.getValue('name')}</div>,
      enableSorting: false,
      enableHiding: false,
    },
    ...uniqueHabitats.map(
      (habitat): ColumnDef<NaceCode> => ({
        accessorKey: habitat,
        header: habitat,
        cell: ({ row }) => {
          const hasHabitat = row.original.habitats?.some((h) => h.name === habitat)
          return hasHabitat ? <CheckIcon className="size-4 text-green-500" /> : null
        },
      })
    ),
  ]

  return <DataTable columns={columns} data={filteredNaceCodes} filterIdColumn={'name'} />
}

export default NaceCodeTable
